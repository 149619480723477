export default [

{
id: 1,
title: "ml5.js Sound Classifier Bubble Popper",
link: "https://dmarcisovska.medium.com/ml5-js-sound-classifier-bubble-popper-fac1546a5bad?source=rss-74784e1b3be0------2",
pubDate: "July 20, 2020",
image: "https://cdn-images-1.medium.com/max/2500/1*1s6JUR-vWpxdPMFMxH5PHA.jpeg",
contentSnippet: "A live example of the code: https://dmarcisovska.github.io/ml5-bubble-popper/ Continue reading on Medium »",
guid: "https://medium.com/p/fac1546a5bad",
},
{
id: 2,
title: "ml5 Feature Extractor Mood Background Changer",
link: "https://dmarcisovska.medium.com/ml5-feature-extractor-mood-background-changer-cd6fa61d9969?source=rss-74784e1b3be0------2",
pubDate: "June 04, 2020",
image: "https://cdn-images-1.medium.com/max/2202/1*9po5xNKRoL-i7JYUP_Jcfg.jpeg",
contentSnippet: "A live example of the code: https://dmarcisovska.github.io/ml5-feature-extractor-mood-background-changer/ Continue reading on Medium »",
guid: "https://medium.com/p/cd6fa61d9969",
},
{
id: 3,
title: "Using ml5.js, poseNet, p5.js to Turn Myself Into a Cat",
link: "https://dmarcisovska.medium.com/using-ml5-js-posenet-p5-js-to-turn-myself-into-a-cat-d14644c5f426",
pubDate: "May 26, 2020",
image: "https://cdn-images-1.medium.com/max/2000/1*Ig8Ujq6HeKka9kpjYfLaFg.jpeg",
contentSnippet: "A live example of the code: https://dmarcisovska.github.io/ml5-posenet-cat/ Continue reading on Medium »",
guid: "https://medium.com/p/d14644c5f426",

},
{
id: 4,
title: "ml5.js Image Classifier",
link: "https://dmarcisovska.medium.com/ml5-js-image-classifier-ed4b21ae9dd1?source=rss-74784e1b3be0------2",
pubDate: "May 23, 2020",
image: "https://cdn-images-1.medium.com/max/2000/1*akrSYceJ59WjdPgb-rS2Cw.jpeg",
contentSnippet: "A live example of the code: https://dmarcisovska.github.io/ml5js-image-classifier/ Continue reading on Medium »",
guid: "https://medium.com/p/ed4b21ae9dd1",
},
{
id: 5,
title: "JavaScript Lottery Number Generator",
link: "https://dmarcisovska.medium.com/javascript-lottery-number-generator-1e1f82090d53?source=rss-74784e1b3be0------2",
pubDate: "May 6, 2020",
image: "https://cdn-images-1.medium.com/max/1024/0*qz_QUDSlZeSwNvWl.png",
contentSnippet: "For the last fews weeks I have been doing different JavaScript projects to help increase my JavaScript skill set. I felt that creating a… Continue reading on Medium »",
guid: "https://medium.com/p/1e1f82090d53",
},
{
id: 5,
title: "Build a JavaScript Tip Calculator",
link: "https://dmarcisovska.medium.com/build-a-javascript-tip-calculator-71486ea6b17?source=rss-74784e1b3be0------2",
pubDate: "April 23, 2020",
image: "https://cdn-images-1.medium.com/max/2000/0*BfFYU9E3sHS2O6Kc.jpg",
contentSnippet: "I am on a mission to practice as much JavaScript as I can by completing different projects. I decided on this project because it is… Continue reading on Medium »",
guid: "https://medium.com/p/71486ea6b17",
},
{
id: 6,
title: "Create a JavaScript Background Changer",
link: "https://dmarcisovska.medium.com/create-a-javascript-background-changer-48c2b3e87734?source=rss-74784e1b3be0------2",
pubDate: "March 29, 2020",
image:"https://cdn-images-1.medium.com/max/1024/0*WBIxN7kYDcwIj0w8.jpg",
guid: "https://medium.com/p/48c2b3e87734",
},
];